import React, { Component, Suspense, lazy } from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";

const Homepage = lazy(() => import("./pages/Homepage/Homepage.js"));
const Projects = lazy(() => import("./pages/Projects/Projects.js"));
const AboutUs = lazy(() => import("./pages/AboutUs/AboutUs.js"));
const NotFound = lazy(() => import("./pages/NotFound/NotFound.js"));
const Project = lazy(() => import("./pages/Project/Project.js"));

class App extends Component {
  render() {
    return (
      <div className="App">
        <BrowserRouter>
          <Suspense fallback={<div></div>}>
            <Routes>
              <Route exact path="/" element={<Homepage />} />
              <Route path="/projects" element={<Projects />} />
              <Route path="/about_us" element={<AboutUs />} />
              <Route path="/project/:id" element={<Project />} />
              <Route path="/*" element={<NotFound />} />
            </Routes>
          </Suspense>
        </BrowserRouter>
      </div>
    );
  }
}

export default App;
